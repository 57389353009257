.color-navbar {
  background-color: #000321 !important;
}

.custom-navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  /* Altura del borde inferior */
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  /* Ancho del borde inferior */
  border-bottom: 1px solid #8FB93E;
  /* Cambiar el color del borde según sea necesario */
}

.custom-navbar {
  transition: top 0.3s;
}

.custom-navbar.sticky {
  position: sticky;
  top: 0;
  z-index: 1030;
}

.nav-link-custom {
  margin-right: 1rem;
  /* Ajusta el valor según tus necesidades */
}

.nav-link-custom:hover {
  color: #8FB93E !important;
  /* Cambia el color según tus necesidades */
}

.navbar-toggler {
  margin-left: auto;
  /* Mueve el toggler al lado derecho */
}

.navbar-collapse {
  justify-content: flex-end;
  /* Alinea los elementos colapsados a la derecha */
}


@media (max-width: 767.98px) {

  /* Ajustado para dispositivos md */
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #000321;
    display: flex;
    justify-content: space-between;
    /* Espacia el contenido entre el logo y el toggler */
    padding: 0 15px;
  }

  .navbar-collapse {
    position: fixed;
    top: 56px;
    /* Altura del navbar */
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    /* Altura del viewport menos la altura del navbar */
    background-color: #000321;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
    /* Espacio para los enlaces en la parte inferior */
  }

  .navbar-collapse .navbar-nav {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .navbar-collapse .nav-link-custom {
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 24px;
  }

  .navbar-nav {
    position: absolute;
    bottom: 50px;
    /* Ajusta la posición según sea necesario */
    width: 100%;
  }

  .navbar-brand {
    position: relative;
    z-index: 2;
    margin-right: auto;
    /* Asegura que el logo esté a la izquierda */
  }

  .navbar-toggler {
    position: relative;
    z-index: 2;
    margin-left: auto;
    /* Asegura que el toggler esté a la derecha */

  }

  .navbar-brand img {
    width: 120px;
    /* Ajusta el ancho del logo en modo responsive */
    height: auto;
    /* Mantiene la proporción del logo */
  }
}





.home-background {
  background-size: cover;
  background-position: center;
  height: 80vh;
  width: 100%;
  position: relative;
}


@media (max-width: 900px) {
  .home-background {
    height: 110vh;
  }
}

.home-background::after,
.home-background::before {
  content: "";
  position: absolute;
  width: 100%;
  z-index: 0;
}

.vectores::after,
.vectores::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
}

.vectores::after {
  bottom: 1rem;
  height: 3rem;
  /* Ajustar según sea necesario */
  background: white;
  transform: skewY(-6deg);
  /* Ajustar según sea necesario */
  transform-origin: bottom right;
  z-index: 2;
}

.vectores::before {
  bottom: -3rem;
  height: 2.4rem;
  /* Ajustar según sea necesario */
  background: #8FB93E;
  transform: skewY(-5deg);
  transform-origin: bottom left;
  z-index: 3;
}

@media (max-width: 768px) {
  .vectores::after {
    height: 3rem;
    /* Ajustar según sea necesario */
    bottom: -0.7rem;
  }

  .vectores::before {
    height: 2rem;
    /* Ajustar según sea necesario */
    bottom: -2rem;
  }
}

@media (min-width: 1024px) {
  .vectores::after {
    height: 6rem;
    /* Ajustar según sea necesario */
    bottom: -0rem;
  }

  .vectores::before {
    height: 3rem;
    /* Ajustar según sea necesario */
  }

  @media (min-width: 1440px) {

    .vectores::after {
      height: 8vw;
      /* Ajustar según sea necesario */
      transform: skewY(-3deg);
      /* Ajustar según sea necesario */
      bottom: -1.5rem;

    }

    .vectores::before {
      height: 2vw;
      /* Ajustar según sea necesario */
      transform: skewY(-3deg);
      bottom: 0rem;
    }
  }
}

.overlay {
  background-color: rgba(0, 3, 33, 0.9);
  /* Color con 90% de transparencia */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.h-100 {
  height: 100%;
}

.align-items-center {
  align-items: center !important;
}

.text-white {
  color: white !important;
}

.text-green {
  color: #8FB93E;
}

.text-g200 {
  color: #AFD89D;
}

.text-g300 {
  color: #0B2529;
}

.font-italic {
  font-family: 'Style Italic';
}

.font-jura {
  font-family: 'Jura';
}


.custom-button {
  background-color: #8FB93E !important;
  border: none !important;
  color: white !important;
  padding: 10px 20px;
  font-size: 1rem !important;
  cursor: pointer;
  font-family: 'Jura', sans-serif;
  /* Aplicar la fuente Jura al botón */
}

.responsive-button {
  background-color: #0B2529 !important;
  border: none !important;
  width: 85vw;
  color: white !important;
  padding: 10px 20px;
  font-size: 1rem !important;
  cursor: pointer;
  font-family: 'Jura', sans-serif;
  /* Aplicar la fuente Jura al botón */
}


/* styles.css */

.hero-heading h1 {
  font-weight: bolder;
}

@media (max-width: 575.98px) {
  .hero-heading h1 {
    font-size: 2.5rem;
  }

  .hero-heading h5 {
    font-size: 1.4rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hero-heading h1 {
    font-size: 2.5rem;
  }

  .hero-heading h5 {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hero-heading h1 {
    font-size: 3rem;
  }

  .hero-heading h5 {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hero-heading h1 {
    font-size: 3.5rem;
  }

  .hero-heading h5 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .hero-heading h1 {
    font-size: 4rem;
  }

  .hero-heading h5 {
    font-size: 2rem;
  }
}

.quote-style {
  background: rgba(175, 216, 157, 0.15) !important;
  border: 1px solid #AFD89D !important;
  border-radius: 20px !important;
}

.py-button {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.bg-green {
  background-color: #82BD69 !important;
}

.bg-g200 {
  background-color: #AFD89D !important;
}

.post-title-h1-primary {
  font-family: 'Jura', sans-serif;
  font-weight: 700;
  font-size: 2.813rem;
}

.post-p {
  font-size: 1.563rem;
}

.post-p-small {
  font-size: 1rem;
}

.divider-solid-green {
  border-top: 1px solid #8fb93e;
}

#blogCard.card {
  border: none;
  background-color: #f6faf3;

  .card-title {
    font-family: 'Jura', sans-serif;
    font-weight: 700;
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.75rem;
  }
}

#blogCol.col {
  transition: transform 0.3s;
}

#blogCol.col :hover {
  transform: scale(1.05);
}

#postInfoHead>*,
#postNav>*,
#postFooter>*,
#postNavFooter>* {
  font-family: 'Jura', sans-serif;
  font-weight: 700;
}

#postNav,
#postNavFooter {

  button,
  img {
    color: black;
    transition: transform 0.3s;
  }

  button:hover,
  img:hover {
    transform: scale(1.05);
  }


}


@media(width <= 767.98px) {

  #postNav ,
  #postInfoHead {
    .post-p-small {
      font-size: 0.75rem;
    }
  }

  #post {
    .post-title-h1-primary {
      font-size: 1.6rem;
    }

    .post-p {
      font-size: 0.9rem;
    }
  }
}