.gracias-background {
    background-color: #000321;
    color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gracias-title {
    font-size: 2.5rem;
    font-family: 'Jura', sans-serif;
    margin-bottom: 1rem;
  }
  
  .gracias-subtitle {
    color: #8FB93E;
    font-family: 'Jura', sans-serif;
    margin-bottom: 2rem;
  }
  
  .gracias-button {
    background-color: #8FB93E;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  .gracias-button:hover {
    background-color: #75A73E;
  }
  