.custom-card {
  position: relative;
  overflow: hidden;
  border: none;
}

.custom-card .card-img-top {
  height: 300px;
  object-fit: cover;
  
  z-index: 0; /* Coloca la imagen en un nivel más bajo */
}


.custom-card .card-body {
  position: relative;
  background-color: white;
  padding: 20px;
  z-index: 1;
  margin-top: -40px; /* Ajustar según sea necesario para mover el contenido hacia arriba */
  padding-top: 50px; /* Añadir espacio adicional para cubrir el área debajo de la línea */
}

.custom-card .card-img-top::after {
  content: "";
  position: absolute;
  bottom: -20px; /* Ajustar para que el borde diagonal se vea correctamente */
  left: 0;
  width: 100%;
  height: 80px; /* Ajustar según sea necesario */
  background: rgb(255, 0, 0); /* Color del borde diagonal */
  transform: skewY(-60deg);
  transform-origin: bottom left;
  z-index: 1; /* Asegúrate de que el pseudo-elemento esté sobre la imagen */
}

.custom-card .card-body::before {
  content: "";
  position: absolute;
  top: 0; /* Ajusta la posición vertical */
  left: 0;
  width: 100%;
  height: 25px; /* Ajustar altura para cubrir el área deseada */
  background: #8FB93E;
  transform: skewY(-8deg);
  transform-origin: top left;
  z-index: 2;
}

.custom-card .card-body:hover::before {
  background: #0B2529;
}

.custom-card .card-icon {
  position: absolute;
  top: -70px;
  right: 20px; /* Mover el icono al lado derecho */
  width: 70px; /* Aumentar el tamaño del círculo */
  height: 70px; /* Aumentar el tamaño del círculo */
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #8FB93E;
  transition: border-color 0.3s;
  z-index: 2;
}

.custom-card .card-body:hover .card-icon {
  border-color: #0B2529;
}

.custom-card .card-icon img {
  width: 45px; /* Aumentar el tamaño de la parte interna del icono */
  height: 45px; /* Aumentar el tamaño de la parte interna del icono */
}

.custom-button {
  background-color: #8FB93E;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: block; /* Hacer que el botón se alinee verticalmente */
  margin-top: 20px; /* Añadir espacio entre el texto y el botón */
  margin-left: auto; /* Alinear el botón a la derecha */
}


.custom-button:hover {
  background-color: #0B2529 !important;
}
