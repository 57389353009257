.faq-section {
  background-color: #000321 !important;
  color: #fff !important;
  padding: 50px 0;
}

.faq-section h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Jura', sans-serif;
}

.faq-section p {
  text-align: center;
  margin-bottom: 40px;
  color: #8FB93E;
}

.accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 15px;
}

.accordion-header {
  background-color: #000321 !important;
  border: 1px solid #fff !important;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button {
  color: #fff !important;
  text-align: left;
  width: 100%;
  padding: 0;
  font-family: 'Jura', sans-serif !important; 
  background-color: #000321 !important;
  border: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #000321 !important;
}

.accordion-body {
  background-color: #000321 !important;
  border: 1px solid #fff !important;
  border-top: none;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  font-family: 'Jura', sans-serif !important; 
  color : #fff !important;
}

